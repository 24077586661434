import { BlockTypesProps, AllBlockProps } from "types/components";

import Skills from "./Skills";
import Hobbies from "./Hobbies";
import Education from "./Education";
import OpenSources from "./OpenSources";
import Experiences from "./Experiences";
import Volunteerings from "./Volunteerings";

import HeadLine from "../HeadLine";

function Block({ type, value }: AllBlockProps) {
  return (
    <div className="mb-8">
      <HeadLine type={type} className="mb-8" />

      {type === BlockTypesProps.SKILLS && <Skills values={value} />}
      {type === BlockTypesProps.HOBBIES && <Hobbies values={value} />}
      {type === BlockTypesProps.EDUCATION && <Education values={value} />}
      {type === BlockTypesProps.EXPERIENCE && <Experiences values={value} />}
      {type === BlockTypesProps.OPEN_SOURCE && <OpenSources values={value} />}
      {type === BlockTypesProps.VOLUNTEERING && (
        <Volunteerings values={value} />
      )}
    </div>
  );
}

export default Block;
