/* eslint-disable no-nested-ternary */
import { faChrome } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import type { HeaderSocialProps } from "types/components";

import Icon from "../Icon";

function HeaderBioBotttom({ type, text }: HeaderSocialProps) {
  const onClick = () => {
    let url = text;

    if (type === "phone") {
      url = `tel:${url}`;
    } else if (type === "email") {
      url = `mailto:${url}`;
    }

    window.open(url);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="flex mr-6 cursor-pointer mt-6" onClick={onClick}>
      {type === "phone" ? (
        <Icon icon={faPhone} />
      ) : type === "email" ? (
        <Icon icon={faEnvelope} />
      ) : type === "website" ? (
        <Icon icon={faChrome} />
      ) : null}
      <div>{text}</div>
    </div>
  );
}

export default HeaderBioBotttom;
